import type { PluginOptions } from 'vue-toastification'
import Toast, { POSITION } from 'vue-toastification'

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const options: PluginOptions = {
  // You can set your default options here
  transition: 'Vue-Toastification__fade',
  position: POSITION.BOTTOM_RIGHT,
  maxToasts: 3,
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, options)
})
