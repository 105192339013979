import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as indexMyUOJMAwXCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/index.vue?macro=true";
import { default as _91id_93Jfe0bLnpdwMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue?macro=true";
import { default as indextmUcPdd9rKMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue?macro=true";
import { default as createuFv8a1UUWCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue?macro=true";
import { default as indexCfTpcTOgpIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue?macro=true";
import { default as accountwAViA1ItYGMeta } from "/opt/atlassian/pipelines/agent/build/pages/account.vue?macro=true";
import { default as _91slug_93E8CrMx4qERMeta } from "/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue?macro=true";
import { default as indexKFV3t0PEhcMeta } from "/opt/atlassian/pipelines/agent/build/pages/advice/index.vue?macro=true";
import { default as _91slug_93qZToloRQByMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue?macro=true";
import { default as indexLAlQRJLYeOMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/index.vue?macro=true";
import { default as mailingeT3uozD3vVMeta } from "/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue?macro=true";
import { default as completedtWMem1gjYPMeta } from "/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue?macro=true";
import { default as indexNl1dY6Uo62Meta } from "/opt/atlassian/pipelines/agent/build/pages/cart/index.vue?macro=true";
import { default as contactggdDqdGlSrMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact.vue?macro=true";
import { default as _91slug_93V0KlQ6gFvXMeta } from "/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue?macro=true";
import { default as indexFiIS95PIYKMeta } from "/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue?macro=true";
import { default as _91id_93jUTZIqVjHsMeta } from "/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue?macro=true";
import { default as _91_46_46_46filters_93uy3IREXj2vMeta } from "/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue?macro=true";
import { default as _91slug_93RMqW89BUslMeta } from "/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue?macro=true";
import { default as index0SDYLFGbIfMeta } from "/opt/atlassian/pipelines/agent/build/pages/green/index.vue?macro=true";
import { default as history_45newMfQUxWsebfMeta } from "/opt/atlassian/pipelines/agent/build/pages/history-new.vue?macro=true";
import { default as historyAXOWr1kzE5Meta } from "/opt/atlassian/pipelines/agent/build/pages/history.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as installationdpe9K2Xs0pMeta } from "/opt/atlassian/pipelines/agent/build/pages/installation.vue?macro=true";
import { default as _91id_93HB05hQeABuMeta } from "/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue?macro=true";
import { default as indexmOXYyonYhKMeta } from "/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as password_45resetmGOUey9uWOMeta } from "/opt/atlassian/pipelines/agent/build/pages/password-reset.vue?macro=true";
import { default as passwordZy8kmdmsFWMeta } from "/opt/atlassian/pipelines/agent/build/pages/password.vue?macro=true";
import { default as _91id_93MM3FvhrRQzMeta } from "/opt/atlassian/pipelines/agent/build/pages/product/[id].vue?macro=true";
import { default as _91type_93EYzK05ukG2Meta } from "/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue?macro=true";
import { default as _91_46_46_46slug_93ahC5nd3s80Meta } from "/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue?macro=true";
import { default as quick_45adddADd42qHnbMeta } from "/opt/atlassian/pipelines/agent/build/pages/quick-add.vue?macro=true";
import { default as register_45as_45dealerFHqkeWGTdJMeta } from "/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue?macro=true";
import { default as registerQphDd3L40XMeta } from "/opt/atlassian/pipelines/agent/build/pages/register.vue?macro=true";
import { default as _91type_93ml185TTSVZMeta } from "/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue?macro=true";
import { default as service0rCUCoSmvhMeta } from "/opt/atlassian/pipelines/agent/build/pages/service.vue?macro=true";
import { default as teamn1IHArbaS9Meta } from "/opt/atlassian/pipelines/agent/build/pages/team.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug___nl",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/nl/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Sb3zmP9EPhMeta?.name ?? "slug___fr",
    path: _91_46_46_46slug_93Sb3zmP9EPhMeta?.path ?? "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Sb3zmP9EPhMeta || {},
    alias: _91_46_46_46slug_93Sb3zmP9EPhMeta?.alias || [],
    redirect: _91_46_46_46slug_93Sb3zmP9EPhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/account",
    children: [
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jfe0bLnpdwMeta?.name ?? "account-orders-id",
    path: _91id_93Jfe0bLnpdwMeta?.path ?? "orders/:id()",
    meta: _91id_93Jfe0bLnpdwMeta || {},
    alias: _91id_93Jfe0bLnpdwMeta?.alias || [],
    redirect: _91id_93Jfe0bLnpdwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders",
    path: indextmUcPdd9rKMeta?.path ?? "orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: createuFv8a1UUWCMeta?.name ?? "account-retours-create",
    path: createuFv8a1UUWCMeta?.path ?? "retours/create",
    meta: createuFv8a1UUWCMeta || {},
    alias: createuFv8a1UUWCMeta?.alias || [],
    redirect: createuFv8a1UUWCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue").then(m => m.default || m)
  },
  {
    name: indexCfTpcTOgpIMeta?.name ?? "account-retours",
    path: indexCfTpcTOgpIMeta?.path ?? "retours",
    meta: indexCfTpcTOgpIMeta || {},
    alias: indexCfTpcTOgpIMeta?.alias || [],
    redirect: indexCfTpcTOgpIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/nl/account",
    children: [
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account___nl",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jfe0bLnpdwMeta?.name ?? "account-orders-id___nl",
    path: _91id_93Jfe0bLnpdwMeta?.path ?? "orders/:id()",
    meta: _91id_93Jfe0bLnpdwMeta || {},
    alias: _91id_93Jfe0bLnpdwMeta?.alias || [],
    redirect: _91id_93Jfe0bLnpdwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders___nl",
    path: indextmUcPdd9rKMeta?.path ?? "orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: createuFv8a1UUWCMeta?.name ?? "account-retours-create___nl",
    path: createuFv8a1UUWCMeta?.path ?? "retours/create",
    meta: createuFv8a1UUWCMeta || {},
    alias: createuFv8a1UUWCMeta?.alias || [],
    redirect: createuFv8a1UUWCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue").then(m => m.default || m)
  },
  {
    name: indexCfTpcTOgpIMeta?.name ?? "account-retours___nl",
    path: indexCfTpcTOgpIMeta?.path ?? "retours",
    meta: indexCfTpcTOgpIMeta || {},
    alias: indexCfTpcTOgpIMeta?.alias || [],
    redirect: indexCfTpcTOgpIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/en/account",
    children: [
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account___en",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jfe0bLnpdwMeta?.name ?? "account-orders-id___en",
    path: _91id_93Jfe0bLnpdwMeta?.path ?? "orders/:id()",
    meta: _91id_93Jfe0bLnpdwMeta || {},
    alias: _91id_93Jfe0bLnpdwMeta?.alias || [],
    redirect: _91id_93Jfe0bLnpdwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders___en",
    path: indextmUcPdd9rKMeta?.path ?? "orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: createuFv8a1UUWCMeta?.name ?? "account-retours-create___en",
    path: createuFv8a1UUWCMeta?.path ?? "retours/create",
    meta: createuFv8a1UUWCMeta || {},
    alias: createuFv8a1UUWCMeta?.alias || [],
    redirect: createuFv8a1UUWCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue").then(m => m.default || m)
  },
  {
    name: indexCfTpcTOgpIMeta?.name ?? "account-retours___en",
    path: indexCfTpcTOgpIMeta?.path ?? "retours",
    meta: indexCfTpcTOgpIMeta || {},
    alias: indexCfTpcTOgpIMeta?.alias || [],
    redirect: indexCfTpcTOgpIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/de/account",
    children: [
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account___de",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jfe0bLnpdwMeta?.name ?? "account-orders-id___de",
    path: _91id_93Jfe0bLnpdwMeta?.path ?? "orders/:id()",
    meta: _91id_93Jfe0bLnpdwMeta || {},
    alias: _91id_93Jfe0bLnpdwMeta?.alias || [],
    redirect: _91id_93Jfe0bLnpdwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders___de",
    path: indextmUcPdd9rKMeta?.path ?? "orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: createuFv8a1UUWCMeta?.name ?? "account-retours-create___de",
    path: createuFv8a1UUWCMeta?.path ?? "retours/create",
    meta: createuFv8a1UUWCMeta || {},
    alias: createuFv8a1UUWCMeta?.alias || [],
    redirect: createuFv8a1UUWCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue").then(m => m.default || m)
  },
  {
    name: indexCfTpcTOgpIMeta?.name ?? "account-retours___de",
    path: indexCfTpcTOgpIMeta?.path ?? "retours",
    meta: indexCfTpcTOgpIMeta || {},
    alias: indexCfTpcTOgpIMeta?.alias || [],
    redirect: indexCfTpcTOgpIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    path: accountwAViA1ItYGMeta?.path ?? "/fr/account",
    children: [
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account___fr",
    path: indexMyUOJMAwXCMeta?.path ?? "",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jfe0bLnpdwMeta?.name ?? "account-orders-id___fr",
    path: _91id_93Jfe0bLnpdwMeta?.path ?? "orders/:id()",
    meta: _91id_93Jfe0bLnpdwMeta || {},
    alias: _91id_93Jfe0bLnpdwMeta?.alias || [],
    redirect: _91id_93Jfe0bLnpdwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders___fr",
    path: indextmUcPdd9rKMeta?.path ?? "orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: createuFv8a1UUWCMeta?.name ?? "account-retours-create___fr",
    path: createuFv8a1UUWCMeta?.path ?? "retours/create",
    meta: createuFv8a1UUWCMeta || {},
    alias: createuFv8a1UUWCMeta?.alias || [],
    redirect: createuFv8a1UUWCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/create.vue").then(m => m.default || m)
  },
  {
    name: indexCfTpcTOgpIMeta?.name ?? "account-retours___fr",
    path: indexCfTpcTOgpIMeta?.path ?? "retours",
    meta: indexCfTpcTOgpIMeta || {},
    alias: indexCfTpcTOgpIMeta?.alias || [],
    redirect: indexCfTpcTOgpIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/retours/index.vue").then(m => m.default || m)
  }
],
    name: accountwAViA1ItYGMeta?.name ?? undefined,
    meta: accountwAViA1ItYGMeta || {},
    alias: accountwAViA1ItYGMeta?.alias || [],
    redirect: accountwAViA1ItYGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E8CrMx4qERMeta?.name ?? "advice-slug",
    path: _91slug_93E8CrMx4qERMeta?.path ?? "/advice/:slug()",
    meta: _91slug_93E8CrMx4qERMeta || {},
    alias: _91slug_93E8CrMx4qERMeta?.alias || [],
    redirect: _91slug_93E8CrMx4qERMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E8CrMx4qERMeta?.name ?? "advice-slug___nl",
    path: _91slug_93E8CrMx4qERMeta?.path ?? "/nl/advice/:slug()",
    meta: _91slug_93E8CrMx4qERMeta || {},
    alias: _91slug_93E8CrMx4qERMeta?.alias || [],
    redirect: _91slug_93E8CrMx4qERMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E8CrMx4qERMeta?.name ?? "advice-slug___en",
    path: _91slug_93E8CrMx4qERMeta?.path ?? "/en/advice/:slug()",
    meta: _91slug_93E8CrMx4qERMeta || {},
    alias: _91slug_93E8CrMx4qERMeta?.alias || [],
    redirect: _91slug_93E8CrMx4qERMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E8CrMx4qERMeta?.name ?? "advice-slug___de",
    path: _91slug_93E8CrMx4qERMeta?.path ?? "/de/advice/:slug()",
    meta: _91slug_93E8CrMx4qERMeta || {},
    alias: _91slug_93E8CrMx4qERMeta?.alias || [],
    redirect: _91slug_93E8CrMx4qERMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E8CrMx4qERMeta?.name ?? "advice-slug___fr",
    path: _91slug_93E8CrMx4qERMeta?.path ?? "/fr/advice/:slug()",
    meta: _91slug_93E8CrMx4qERMeta || {},
    alias: _91slug_93E8CrMx4qERMeta?.alias || [],
    redirect: _91slug_93E8CrMx4qERMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKFV3t0PEhcMeta?.name ?? "advice",
    path: indexKFV3t0PEhcMeta?.path ?? "/advice",
    meta: indexKFV3t0PEhcMeta || {},
    alias: indexKFV3t0PEhcMeta?.alias || [],
    redirect: indexKFV3t0PEhcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFV3t0PEhcMeta?.name ?? "advice___nl",
    path: indexKFV3t0PEhcMeta?.path ?? "/nl/advice",
    meta: indexKFV3t0PEhcMeta || {},
    alias: indexKFV3t0PEhcMeta?.alias || [],
    redirect: indexKFV3t0PEhcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFV3t0PEhcMeta?.name ?? "advice___en",
    path: indexKFV3t0PEhcMeta?.path ?? "/en/advice",
    meta: indexKFV3t0PEhcMeta || {},
    alias: indexKFV3t0PEhcMeta?.alias || [],
    redirect: indexKFV3t0PEhcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFV3t0PEhcMeta?.name ?? "advice___de",
    path: indexKFV3t0PEhcMeta?.path ?? "/de/advice",
    meta: indexKFV3t0PEhcMeta || {},
    alias: indexKFV3t0PEhcMeta?.alias || [],
    redirect: indexKFV3t0PEhcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFV3t0PEhcMeta?.name ?? "advice___fr",
    path: indexKFV3t0PEhcMeta?.path ?? "/fr/advice",
    meta: indexKFV3t0PEhcMeta || {},
    alias: indexKFV3t0PEhcMeta?.alias || [],
    redirect: indexKFV3t0PEhcMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advice/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZToloRQByMeta?.name ?? "blog-slug",
    path: _91slug_93qZToloRQByMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93qZToloRQByMeta || {},
    alias: _91slug_93qZToloRQByMeta?.alias || [],
    redirect: _91slug_93qZToloRQByMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZToloRQByMeta?.name ?? "blog-slug___nl",
    path: _91slug_93qZToloRQByMeta?.path ?? "/nl/blog/:slug()",
    meta: _91slug_93qZToloRQByMeta || {},
    alias: _91slug_93qZToloRQByMeta?.alias || [],
    redirect: _91slug_93qZToloRQByMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZToloRQByMeta?.name ?? "blog-slug___en",
    path: _91slug_93qZToloRQByMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93qZToloRQByMeta || {},
    alias: _91slug_93qZToloRQByMeta?.alias || [],
    redirect: _91slug_93qZToloRQByMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZToloRQByMeta?.name ?? "blog-slug___de",
    path: _91slug_93qZToloRQByMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93qZToloRQByMeta || {},
    alias: _91slug_93qZToloRQByMeta?.alias || [],
    redirect: _91slug_93qZToloRQByMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZToloRQByMeta?.name ?? "blog-slug___fr",
    path: _91slug_93qZToloRQByMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93qZToloRQByMeta || {},
    alias: _91slug_93qZToloRQByMeta?.alias || [],
    redirect: _91slug_93qZToloRQByMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog",
    path: indexLAlQRJLYeOMeta?.path ?? "/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___nl",
    path: indexLAlQRJLYeOMeta?.path ?? "/nl/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___en",
    path: indexLAlQRJLYeOMeta?.path ?? "/en/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___de",
    path: indexLAlQRJLYeOMeta?.path ?? "/de/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___fr",
    path: indexLAlQRJLYeOMeta?.path ?? "/fr/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: mailingeT3uozD3vVMeta?.name ?? "campagne-mailing",
    path: mailingeT3uozD3vVMeta?.path ?? "/campagne/mailing",
    meta: mailingeT3uozD3vVMeta || {},
    alias: mailingeT3uozD3vVMeta?.alias || [],
    redirect: mailingeT3uozD3vVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue").then(m => m.default || m)
  },
  {
    name: mailingeT3uozD3vVMeta?.name ?? "campagne-mailing___nl",
    path: mailingeT3uozD3vVMeta?.path ?? "/nl/campagne/mailing",
    meta: mailingeT3uozD3vVMeta || {},
    alias: mailingeT3uozD3vVMeta?.alias || [],
    redirect: mailingeT3uozD3vVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue").then(m => m.default || m)
  },
  {
    name: mailingeT3uozD3vVMeta?.name ?? "campagne-mailing___en",
    path: mailingeT3uozD3vVMeta?.path ?? "/en/campagne/mailing",
    meta: mailingeT3uozD3vVMeta || {},
    alias: mailingeT3uozD3vVMeta?.alias || [],
    redirect: mailingeT3uozD3vVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue").then(m => m.default || m)
  },
  {
    name: mailingeT3uozD3vVMeta?.name ?? "campagne-mailing___de",
    path: mailingeT3uozD3vVMeta?.path ?? "/de/campagne/mailing",
    meta: mailingeT3uozD3vVMeta || {},
    alias: mailingeT3uozD3vVMeta?.alias || [],
    redirect: mailingeT3uozD3vVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue").then(m => m.default || m)
  },
  {
    name: mailingeT3uozD3vVMeta?.name ?? "campagne-mailing___fr",
    path: mailingeT3uozD3vVMeta?.path ?? "/fr/campagne/mailing",
    meta: mailingeT3uozD3vVMeta || {},
    alias: mailingeT3uozD3vVMeta?.alias || [],
    redirect: mailingeT3uozD3vVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/campagne/mailing.vue").then(m => m.default || m)
  },
  {
    name: completedtWMem1gjYPMeta?.name ?? "cart-completed",
    path: completedtWMem1gjYPMeta?.path ?? "/cart/completed",
    meta: completedtWMem1gjYPMeta || {},
    alias: completedtWMem1gjYPMeta?.alias || [],
    redirect: completedtWMem1gjYPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue").then(m => m.default || m)
  },
  {
    name: completedtWMem1gjYPMeta?.name ?? "cart-completed___nl",
    path: completedtWMem1gjYPMeta?.path ?? "/nl/cart/completed",
    meta: completedtWMem1gjYPMeta || {},
    alias: completedtWMem1gjYPMeta?.alias || [],
    redirect: completedtWMem1gjYPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue").then(m => m.default || m)
  },
  {
    name: completedtWMem1gjYPMeta?.name ?? "cart-completed___en",
    path: completedtWMem1gjYPMeta?.path ?? "/en/cart/completed",
    meta: completedtWMem1gjYPMeta || {},
    alias: completedtWMem1gjYPMeta?.alias || [],
    redirect: completedtWMem1gjYPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue").then(m => m.default || m)
  },
  {
    name: completedtWMem1gjYPMeta?.name ?? "cart-completed___de",
    path: completedtWMem1gjYPMeta?.path ?? "/de/cart/completed",
    meta: completedtWMem1gjYPMeta || {},
    alias: completedtWMem1gjYPMeta?.alias || [],
    redirect: completedtWMem1gjYPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue").then(m => m.default || m)
  },
  {
    name: completedtWMem1gjYPMeta?.name ?? "cart-completed___fr",
    path: completedtWMem1gjYPMeta?.path ?? "/fr/cart/completed",
    meta: completedtWMem1gjYPMeta || {},
    alias: completedtWMem1gjYPMeta?.alias || [],
    redirect: completedtWMem1gjYPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/completed.vue").then(m => m.default || m)
  },
  {
    name: indexNl1dY6Uo62Meta?.name ?? "cart",
    path: indexNl1dY6Uo62Meta?.path ?? "/cart",
    meta: indexNl1dY6Uo62Meta || {},
    alias: indexNl1dY6Uo62Meta?.alias || [],
    redirect: indexNl1dY6Uo62Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexNl1dY6Uo62Meta?.name ?? "cart___nl",
    path: indexNl1dY6Uo62Meta?.path ?? "/nl/cart",
    meta: indexNl1dY6Uo62Meta || {},
    alias: indexNl1dY6Uo62Meta?.alias || [],
    redirect: indexNl1dY6Uo62Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexNl1dY6Uo62Meta?.name ?? "cart___en",
    path: indexNl1dY6Uo62Meta?.path ?? "/en/cart",
    meta: indexNl1dY6Uo62Meta || {},
    alias: indexNl1dY6Uo62Meta?.alias || [],
    redirect: indexNl1dY6Uo62Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexNl1dY6Uo62Meta?.name ?? "cart___de",
    path: indexNl1dY6Uo62Meta?.path ?? "/de/cart",
    meta: indexNl1dY6Uo62Meta || {},
    alias: indexNl1dY6Uo62Meta?.alias || [],
    redirect: indexNl1dY6Uo62Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexNl1dY6Uo62Meta?.name ?? "cart___fr",
    path: indexNl1dY6Uo62Meta?.path ?? "/fr/cart",
    meta: indexNl1dY6Uo62Meta || {},
    alias: indexNl1dY6Uo62Meta?.alias || [],
    redirect: indexNl1dY6Uo62Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact",
    path: contactggdDqdGlSrMeta?.path ?? "/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___nl",
    path: contactggdDqdGlSrMeta?.path ?? "/nl/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___en",
    path: contactggdDqdGlSrMeta?.path ?? "/en/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___de",
    path: contactggdDqdGlSrMeta?.path ?? "/de/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactggdDqdGlSrMeta?.name ?? "contact___fr",
    path: contactggdDqdGlSrMeta?.path ?? "/fr/contact",
    meta: contactggdDqdGlSrMeta || {},
    alias: contactggdDqdGlSrMeta?.alias || [],
    redirect: contactggdDqdGlSrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0KlQ6gFvXMeta?.name ?? "dealers-slug",
    path: _91slug_93V0KlQ6gFvXMeta?.path ?? "/dealers/:slug()",
    meta: _91slug_93V0KlQ6gFvXMeta || {},
    alias: _91slug_93V0KlQ6gFvXMeta?.alias || [],
    redirect: _91slug_93V0KlQ6gFvXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0KlQ6gFvXMeta?.name ?? "dealers-slug___nl",
    path: _91slug_93V0KlQ6gFvXMeta?.path ?? "/nl/dealers/:slug()",
    meta: _91slug_93V0KlQ6gFvXMeta || {},
    alias: _91slug_93V0KlQ6gFvXMeta?.alias || [],
    redirect: _91slug_93V0KlQ6gFvXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0KlQ6gFvXMeta?.name ?? "dealers-slug___en",
    path: _91slug_93V0KlQ6gFvXMeta?.path ?? "/en/dealers/:slug()",
    meta: _91slug_93V0KlQ6gFvXMeta || {},
    alias: _91slug_93V0KlQ6gFvXMeta?.alias || [],
    redirect: _91slug_93V0KlQ6gFvXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0KlQ6gFvXMeta?.name ?? "dealers-slug___de",
    path: _91slug_93V0KlQ6gFvXMeta?.path ?? "/de/dealers/:slug()",
    meta: _91slug_93V0KlQ6gFvXMeta || {},
    alias: _91slug_93V0KlQ6gFvXMeta?.alias || [],
    redirect: _91slug_93V0KlQ6gFvXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0KlQ6gFvXMeta?.name ?? "dealers-slug___fr",
    path: _91slug_93V0KlQ6gFvXMeta?.path ?? "/fr/dealers/:slug()",
    meta: _91slug_93V0KlQ6gFvXMeta || {},
    alias: _91slug_93V0KlQ6gFvXMeta?.alias || [],
    redirect: _91slug_93V0KlQ6gFvXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexFiIS95PIYKMeta?.name ?? "dealers",
    path: indexFiIS95PIYKMeta?.path ?? "/dealers",
    meta: indexFiIS95PIYKMeta || {},
    alias: indexFiIS95PIYKMeta?.alias || [],
    redirect: indexFiIS95PIYKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFiIS95PIYKMeta?.name ?? "dealers___nl",
    path: indexFiIS95PIYKMeta?.path ?? "/nl/dealers",
    meta: indexFiIS95PIYKMeta || {},
    alias: indexFiIS95PIYKMeta?.alias || [],
    redirect: indexFiIS95PIYKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFiIS95PIYKMeta?.name ?? "dealers___en",
    path: indexFiIS95PIYKMeta?.path ?? "/en/dealers",
    meta: indexFiIS95PIYKMeta || {},
    alias: indexFiIS95PIYKMeta?.alias || [],
    redirect: indexFiIS95PIYKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFiIS95PIYKMeta?.name ?? "dealers___de",
    path: indexFiIS95PIYKMeta?.path ?? "/de/dealers",
    meta: indexFiIS95PIYKMeta || {},
    alias: indexFiIS95PIYKMeta?.alias || [],
    redirect: indexFiIS95PIYKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFiIS95PIYKMeta?.name ?? "dealers___fr",
    path: indexFiIS95PIYKMeta?.path ?? "/fr/dealers",
    meta: indexFiIS95PIYKMeta || {},
    alias: indexFiIS95PIYKMeta?.alias || [],
    redirect: indexFiIS95PIYKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUTZIqVjHsMeta?.name ?? "employee-id",
    path: _91id_93jUTZIqVjHsMeta?.path ?? "/employee/:id()",
    meta: _91id_93jUTZIqVjHsMeta || {},
    alias: _91id_93jUTZIqVjHsMeta?.alias || [],
    redirect: _91id_93jUTZIqVjHsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUTZIqVjHsMeta?.name ?? "employee-id___nl",
    path: _91id_93jUTZIqVjHsMeta?.path ?? "/nl/employee/:id()",
    meta: _91id_93jUTZIqVjHsMeta || {},
    alias: _91id_93jUTZIqVjHsMeta?.alias || [],
    redirect: _91id_93jUTZIqVjHsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUTZIqVjHsMeta?.name ?? "employee-id___en",
    path: _91id_93jUTZIqVjHsMeta?.path ?? "/en/employee/:id()",
    meta: _91id_93jUTZIqVjHsMeta || {},
    alias: _91id_93jUTZIqVjHsMeta?.alias || [],
    redirect: _91id_93jUTZIqVjHsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUTZIqVjHsMeta?.name ?? "employee-id___de",
    path: _91id_93jUTZIqVjHsMeta?.path ?? "/de/employee/:id()",
    meta: _91id_93jUTZIqVjHsMeta || {},
    alias: _91id_93jUTZIqVjHsMeta?.alias || [],
    redirect: _91id_93jUTZIqVjHsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUTZIqVjHsMeta?.name ?? "employee-id___fr",
    path: _91id_93jUTZIqVjHsMeta?.path ?? "/fr/employee/:id()",
    meta: _91id_93jUTZIqVjHsMeta || {},
    alias: _91id_93jUTZIqVjHsMeta?.alias || [],
    redirect: _91id_93jUTZIqVjHsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filters_93uy3IREXj2vMeta?.name ?? "faq-filters",
    path: _91_46_46_46filters_93uy3IREXj2vMeta?.path ?? "/faq/:filters(.*)*",
    meta: _91_46_46_46filters_93uy3IREXj2vMeta || {},
    alias: _91_46_46_46filters_93uy3IREXj2vMeta?.alias || [],
    redirect: _91_46_46_46filters_93uy3IREXj2vMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filters_93uy3IREXj2vMeta?.name ?? "faq-filters___nl",
    path: _91_46_46_46filters_93uy3IREXj2vMeta?.path ?? "/nl/faq/:filters(.*)*",
    meta: _91_46_46_46filters_93uy3IREXj2vMeta || {},
    alias: _91_46_46_46filters_93uy3IREXj2vMeta?.alias || [],
    redirect: _91_46_46_46filters_93uy3IREXj2vMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filters_93uy3IREXj2vMeta?.name ?? "faq-filters___en",
    path: _91_46_46_46filters_93uy3IREXj2vMeta?.path ?? "/en/faq/:filters(.*)*",
    meta: _91_46_46_46filters_93uy3IREXj2vMeta || {},
    alias: _91_46_46_46filters_93uy3IREXj2vMeta?.alias || [],
    redirect: _91_46_46_46filters_93uy3IREXj2vMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filters_93uy3IREXj2vMeta?.name ?? "faq-filters___de",
    path: _91_46_46_46filters_93uy3IREXj2vMeta?.path ?? "/de/faq/:filters(.*)*",
    meta: _91_46_46_46filters_93uy3IREXj2vMeta || {},
    alias: _91_46_46_46filters_93uy3IREXj2vMeta?.alias || [],
    redirect: _91_46_46_46filters_93uy3IREXj2vMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46filters_93uy3IREXj2vMeta?.name ?? "faq-filters___fr",
    path: _91_46_46_46filters_93uy3IREXj2vMeta?.path ?? "/fr/faq/:filters(.*)*",
    meta: _91_46_46_46filters_93uy3IREXj2vMeta || {},
    alias: _91_46_46_46filters_93uy3IREXj2vMeta?.alias || [],
    redirect: _91_46_46_46filters_93uy3IREXj2vMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/faq/[...filters].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RMqW89BUslMeta?.name ?? "green-slug",
    path: _91slug_93RMqW89BUslMeta?.path ?? "/green/:slug()",
    meta: _91slug_93RMqW89BUslMeta || {},
    alias: _91slug_93RMqW89BUslMeta?.alias || [],
    redirect: _91slug_93RMqW89BUslMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RMqW89BUslMeta?.name ?? "green-slug___nl",
    path: _91slug_93RMqW89BUslMeta?.path ?? "/nl/green/:slug()",
    meta: _91slug_93RMqW89BUslMeta || {},
    alias: _91slug_93RMqW89BUslMeta?.alias || [],
    redirect: _91slug_93RMqW89BUslMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RMqW89BUslMeta?.name ?? "green-slug___en",
    path: _91slug_93RMqW89BUslMeta?.path ?? "/en/green/:slug()",
    meta: _91slug_93RMqW89BUslMeta || {},
    alias: _91slug_93RMqW89BUslMeta?.alias || [],
    redirect: _91slug_93RMqW89BUslMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RMqW89BUslMeta?.name ?? "green-slug___de",
    path: _91slug_93RMqW89BUslMeta?.path ?? "/de/green/:slug()",
    meta: _91slug_93RMqW89BUslMeta || {},
    alias: _91slug_93RMqW89BUslMeta?.alias || [],
    redirect: _91slug_93RMqW89BUslMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RMqW89BUslMeta?.name ?? "green-slug___fr",
    path: _91slug_93RMqW89BUslMeta?.path ?? "/fr/green/:slug()",
    meta: _91slug_93RMqW89BUslMeta || {},
    alias: _91slug_93RMqW89BUslMeta?.alias || [],
    redirect: _91slug_93RMqW89BUslMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0SDYLFGbIfMeta?.name ?? "green",
    path: index0SDYLFGbIfMeta?.path ?? "/green",
    meta: index0SDYLFGbIfMeta || {},
    alias: index0SDYLFGbIfMeta?.alias || [],
    redirect: index0SDYLFGbIfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/index.vue").then(m => m.default || m)
  },
  {
    name: index0SDYLFGbIfMeta?.name ?? "green___nl",
    path: index0SDYLFGbIfMeta?.path ?? "/nl/green",
    meta: index0SDYLFGbIfMeta || {},
    alias: index0SDYLFGbIfMeta?.alias || [],
    redirect: index0SDYLFGbIfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/index.vue").then(m => m.default || m)
  },
  {
    name: index0SDYLFGbIfMeta?.name ?? "green___en",
    path: index0SDYLFGbIfMeta?.path ?? "/en/green",
    meta: index0SDYLFGbIfMeta || {},
    alias: index0SDYLFGbIfMeta?.alias || [],
    redirect: index0SDYLFGbIfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/index.vue").then(m => m.default || m)
  },
  {
    name: index0SDYLFGbIfMeta?.name ?? "green___de",
    path: index0SDYLFGbIfMeta?.path ?? "/de/green",
    meta: index0SDYLFGbIfMeta || {},
    alias: index0SDYLFGbIfMeta?.alias || [],
    redirect: index0SDYLFGbIfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/index.vue").then(m => m.default || m)
  },
  {
    name: index0SDYLFGbIfMeta?.name ?? "green___fr",
    path: index0SDYLFGbIfMeta?.path ?? "/fr/green",
    meta: index0SDYLFGbIfMeta || {},
    alias: index0SDYLFGbIfMeta?.alias || [],
    redirect: index0SDYLFGbIfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/green/index.vue").then(m => m.default || m)
  },
  {
    name: history_45newMfQUxWsebfMeta?.name ?? "history-new",
    path: history_45newMfQUxWsebfMeta?.path ?? "/history-new",
    meta: history_45newMfQUxWsebfMeta || {},
    alias: history_45newMfQUxWsebfMeta?.alias || [],
    redirect: history_45newMfQUxWsebfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history-new.vue").then(m => m.default || m)
  },
  {
    name: history_45newMfQUxWsebfMeta?.name ?? "history-new___nl",
    path: history_45newMfQUxWsebfMeta?.path ?? "/nl/history-new",
    meta: history_45newMfQUxWsebfMeta || {},
    alias: history_45newMfQUxWsebfMeta?.alias || [],
    redirect: history_45newMfQUxWsebfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history-new.vue").then(m => m.default || m)
  },
  {
    name: history_45newMfQUxWsebfMeta?.name ?? "history-new___en",
    path: history_45newMfQUxWsebfMeta?.path ?? "/en/history-new",
    meta: history_45newMfQUxWsebfMeta || {},
    alias: history_45newMfQUxWsebfMeta?.alias || [],
    redirect: history_45newMfQUxWsebfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history-new.vue").then(m => m.default || m)
  },
  {
    name: history_45newMfQUxWsebfMeta?.name ?? "history-new___de",
    path: history_45newMfQUxWsebfMeta?.path ?? "/de/history-new",
    meta: history_45newMfQUxWsebfMeta || {},
    alias: history_45newMfQUxWsebfMeta?.alias || [],
    redirect: history_45newMfQUxWsebfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history-new.vue").then(m => m.default || m)
  },
  {
    name: history_45newMfQUxWsebfMeta?.name ?? "history-new___fr",
    path: history_45newMfQUxWsebfMeta?.path ?? "/fr/history-new",
    meta: history_45newMfQUxWsebfMeta || {},
    alias: history_45newMfQUxWsebfMeta?.alias || [],
    redirect: history_45newMfQUxWsebfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history-new.vue").then(m => m.default || m)
  },
  {
    name: historyAXOWr1kzE5Meta?.name ?? "history",
    path: historyAXOWr1kzE5Meta?.path ?? "/history",
    meta: historyAXOWr1kzE5Meta || {},
    alias: historyAXOWr1kzE5Meta?.alias || [],
    redirect: historyAXOWr1kzE5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyAXOWr1kzE5Meta?.name ?? "history___nl",
    path: historyAXOWr1kzE5Meta?.path ?? "/nl/history",
    meta: historyAXOWr1kzE5Meta || {},
    alias: historyAXOWr1kzE5Meta?.alias || [],
    redirect: historyAXOWr1kzE5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyAXOWr1kzE5Meta?.name ?? "history___en",
    path: historyAXOWr1kzE5Meta?.path ?? "/en/history",
    meta: historyAXOWr1kzE5Meta || {},
    alias: historyAXOWr1kzE5Meta?.alias || [],
    redirect: historyAXOWr1kzE5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyAXOWr1kzE5Meta?.name ?? "history___de",
    path: historyAXOWr1kzE5Meta?.path ?? "/de/history",
    meta: historyAXOWr1kzE5Meta || {},
    alias: historyAXOWr1kzE5Meta?.alias || [],
    redirect: historyAXOWr1kzE5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history.vue").then(m => m.default || m)
  },
  {
    name: historyAXOWr1kzE5Meta?.name ?? "history___fr",
    path: historyAXOWr1kzE5Meta?.path ?? "/fr/history",
    meta: historyAXOWr1kzE5Meta || {},
    alias: historyAXOWr1kzE5Meta?.alias || [],
    redirect: historyAXOWr1kzE5Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___nl",
    path: indexOD9t3F2bSJMeta?.path ?? "/nl",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___en",
    path: indexOD9t3F2bSJMeta?.path ?? "/en",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___de",
    path: indexOD9t3F2bSJMeta?.path ?? "/de",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___fr",
    path: indexOD9t3F2bSJMeta?.path ?? "/fr",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: installationdpe9K2Xs0pMeta?.name ?? "installation",
    path: installationdpe9K2Xs0pMeta?.path ?? "/installation",
    meta: installationdpe9K2Xs0pMeta || {},
    alias: installationdpe9K2Xs0pMeta?.alias || [],
    redirect: installationdpe9K2Xs0pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/installation.vue").then(m => m.default || m)
  },
  {
    name: installationdpe9K2Xs0pMeta?.name ?? "installation___nl",
    path: installationdpe9K2Xs0pMeta?.path ?? "/nl/installation",
    meta: installationdpe9K2Xs0pMeta || {},
    alias: installationdpe9K2Xs0pMeta?.alias || [],
    redirect: installationdpe9K2Xs0pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/installation.vue").then(m => m.default || m)
  },
  {
    name: installationdpe9K2Xs0pMeta?.name ?? "installation___en",
    path: installationdpe9K2Xs0pMeta?.path ?? "/en/installation",
    meta: installationdpe9K2Xs0pMeta || {},
    alias: installationdpe9K2Xs0pMeta?.alias || [],
    redirect: installationdpe9K2Xs0pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/installation.vue").then(m => m.default || m)
  },
  {
    name: installationdpe9K2Xs0pMeta?.name ?? "installation___de",
    path: installationdpe9K2Xs0pMeta?.path ?? "/de/installation",
    meta: installationdpe9K2Xs0pMeta || {},
    alias: installationdpe9K2Xs0pMeta?.alias || [],
    redirect: installationdpe9K2Xs0pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/installation.vue").then(m => m.default || m)
  },
  {
    name: installationdpe9K2Xs0pMeta?.name ?? "installation___fr",
    path: installationdpe9K2Xs0pMeta?.path ?? "/fr/installation",
    meta: installationdpe9K2Xs0pMeta || {},
    alias: installationdpe9K2Xs0pMeta?.alias || [],
    redirect: installationdpe9K2Xs0pMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/installation.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HB05hQeABuMeta?.name ?? "jobs-id",
    path: _91id_93HB05hQeABuMeta?.path ?? "/jobs/:id()",
    meta: _91id_93HB05hQeABuMeta || {},
    alias: _91id_93HB05hQeABuMeta?.alias || [],
    redirect: _91id_93HB05hQeABuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HB05hQeABuMeta?.name ?? "jobs-id___nl",
    path: _91id_93HB05hQeABuMeta?.path ?? "/nl/jobs/:id()",
    meta: _91id_93HB05hQeABuMeta || {},
    alias: _91id_93HB05hQeABuMeta?.alias || [],
    redirect: _91id_93HB05hQeABuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HB05hQeABuMeta?.name ?? "jobs-id___en",
    path: _91id_93HB05hQeABuMeta?.path ?? "/en/jobs/:id()",
    meta: _91id_93HB05hQeABuMeta || {},
    alias: _91id_93HB05hQeABuMeta?.alias || [],
    redirect: _91id_93HB05hQeABuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HB05hQeABuMeta?.name ?? "jobs-id___de",
    path: _91id_93HB05hQeABuMeta?.path ?? "/de/jobs/:id()",
    meta: _91id_93HB05hQeABuMeta || {},
    alias: _91id_93HB05hQeABuMeta?.alias || [],
    redirect: _91id_93HB05hQeABuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HB05hQeABuMeta?.name ?? "jobs-id___fr",
    path: _91id_93HB05hQeABuMeta?.path ?? "/fr/jobs/:id()",
    meta: _91id_93HB05hQeABuMeta || {},
    alias: _91id_93HB05hQeABuMeta?.alias || [],
    redirect: _91id_93HB05hQeABuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmOXYyonYhKMeta?.name ?? "jobs",
    path: indexmOXYyonYhKMeta?.path ?? "/jobs",
    meta: indexmOXYyonYhKMeta || {},
    alias: indexmOXYyonYhKMeta?.alias || [],
    redirect: indexmOXYyonYhKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmOXYyonYhKMeta?.name ?? "jobs___nl",
    path: indexmOXYyonYhKMeta?.path ?? "/nl/jobs",
    meta: indexmOXYyonYhKMeta || {},
    alias: indexmOXYyonYhKMeta?.alias || [],
    redirect: indexmOXYyonYhKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmOXYyonYhKMeta?.name ?? "jobs___en",
    path: indexmOXYyonYhKMeta?.path ?? "/en/jobs",
    meta: indexmOXYyonYhKMeta || {},
    alias: indexmOXYyonYhKMeta?.alias || [],
    redirect: indexmOXYyonYhKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmOXYyonYhKMeta?.name ?? "jobs___de",
    path: indexmOXYyonYhKMeta?.path ?? "/de/jobs",
    meta: indexmOXYyonYhKMeta || {},
    alias: indexmOXYyonYhKMeta?.alias || [],
    redirect: indexmOXYyonYhKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmOXYyonYhKMeta?.name ?? "jobs___fr",
    path: indexmOXYyonYhKMeta?.path ?? "/fr/jobs",
    meta: indexmOXYyonYhKMeta || {},
    alias: indexmOXYyonYhKMeta?.alias || [],
    redirect: indexmOXYyonYhKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login___nl",
    path: logineaOYVOrhPDMeta?.path ?? "/nl/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login___en",
    path: logineaOYVOrhPDMeta?.path ?? "/en/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login___de",
    path: logineaOYVOrhPDMeta?.path ?? "/de/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login___fr",
    path: logineaOYVOrhPDMeta?.path ?? "/fr/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmGOUey9uWOMeta?.name ?? "password-reset",
    path: password_45resetmGOUey9uWOMeta?.path ?? "/password-reset",
    meta: password_45resetmGOUey9uWOMeta || {},
    alias: password_45resetmGOUey9uWOMeta?.alias || [],
    redirect: password_45resetmGOUey9uWOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmGOUey9uWOMeta?.name ?? "password-reset___nl",
    path: password_45resetmGOUey9uWOMeta?.path ?? "/nl/password-reset",
    meta: password_45resetmGOUey9uWOMeta || {},
    alias: password_45resetmGOUey9uWOMeta?.alias || [],
    redirect: password_45resetmGOUey9uWOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmGOUey9uWOMeta?.name ?? "password-reset___en",
    path: password_45resetmGOUey9uWOMeta?.path ?? "/en/password-reset",
    meta: password_45resetmGOUey9uWOMeta || {},
    alias: password_45resetmGOUey9uWOMeta?.alias || [],
    redirect: password_45resetmGOUey9uWOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmGOUey9uWOMeta?.name ?? "password-reset___de",
    path: password_45resetmGOUey9uWOMeta?.path ?? "/de/password-reset",
    meta: password_45resetmGOUey9uWOMeta || {},
    alias: password_45resetmGOUey9uWOMeta?.alias || [],
    redirect: password_45resetmGOUey9uWOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmGOUey9uWOMeta?.name ?? "password-reset___fr",
    path: password_45resetmGOUey9uWOMeta?.path ?? "/fr/password-reset",
    meta: password_45resetmGOUey9uWOMeta || {},
    alias: password_45resetmGOUey9uWOMeta?.alias || [],
    redirect: password_45resetmGOUey9uWOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: passwordZy8kmdmsFWMeta?.name ?? "password",
    path: passwordZy8kmdmsFWMeta?.path ?? "/password",
    meta: passwordZy8kmdmsFWMeta || {},
    alias: passwordZy8kmdmsFWMeta?.alias || [],
    redirect: passwordZy8kmdmsFWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password.vue").then(m => m.default || m)
  },
  {
    name: passwordZy8kmdmsFWMeta?.name ?? "password___nl",
    path: passwordZy8kmdmsFWMeta?.path ?? "/nl/password",
    meta: passwordZy8kmdmsFWMeta || {},
    alias: passwordZy8kmdmsFWMeta?.alias || [],
    redirect: passwordZy8kmdmsFWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password.vue").then(m => m.default || m)
  },
  {
    name: passwordZy8kmdmsFWMeta?.name ?? "password___en",
    path: passwordZy8kmdmsFWMeta?.path ?? "/en/password",
    meta: passwordZy8kmdmsFWMeta || {},
    alias: passwordZy8kmdmsFWMeta?.alias || [],
    redirect: passwordZy8kmdmsFWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password.vue").then(m => m.default || m)
  },
  {
    name: passwordZy8kmdmsFWMeta?.name ?? "password___de",
    path: passwordZy8kmdmsFWMeta?.path ?? "/de/password",
    meta: passwordZy8kmdmsFWMeta || {},
    alias: passwordZy8kmdmsFWMeta?.alias || [],
    redirect: passwordZy8kmdmsFWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password.vue").then(m => m.default || m)
  },
  {
    name: passwordZy8kmdmsFWMeta?.name ?? "password___fr",
    path: passwordZy8kmdmsFWMeta?.path ?? "/fr/password",
    meta: passwordZy8kmdmsFWMeta || {},
    alias: passwordZy8kmdmsFWMeta?.alias || [],
    redirect: passwordZy8kmdmsFWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MM3FvhrRQzMeta?.name ?? "product-id",
    path: _91id_93MM3FvhrRQzMeta?.path ?? "/product/:id()",
    meta: _91id_93MM3FvhrRQzMeta || {},
    alias: _91id_93MM3FvhrRQzMeta?.alias || [],
    redirect: _91id_93MM3FvhrRQzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93MM3FvhrRQzMeta?.name ?? "product-id___nl",
    path: _91id_93MM3FvhrRQzMeta?.path ?? "/nl/product/:id()",
    meta: _91id_93MM3FvhrRQzMeta || {},
    alias: _91id_93MM3FvhrRQzMeta?.alias || [],
    redirect: _91id_93MM3FvhrRQzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93MM3FvhrRQzMeta?.name ?? "product-id___en",
    path: _91id_93MM3FvhrRQzMeta?.path ?? "/en/product/:id()",
    meta: _91id_93MM3FvhrRQzMeta || {},
    alias: _91id_93MM3FvhrRQzMeta?.alias || [],
    redirect: _91id_93MM3FvhrRQzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93MM3FvhrRQzMeta?.name ?? "product-id___de",
    path: _91id_93MM3FvhrRQzMeta?.path ?? "/de/product/:id()",
    meta: _91id_93MM3FvhrRQzMeta || {},
    alias: _91id_93MM3FvhrRQzMeta?.alias || [],
    redirect: _91id_93MM3FvhrRQzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93MM3FvhrRQzMeta?.name ?? "product-id___fr",
    path: _91id_93MM3FvhrRQzMeta?.path ?? "/fr/product/:id()",
    meta: _91id_93MM3FvhrRQzMeta || {},
    alias: _91id_93MM3FvhrRQzMeta?.alias || [],
    redirect: _91id_93MM3FvhrRQzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91type_93EYzK05ukG2Meta?.name ?? "products-category-type",
    path: _91type_93EYzK05ukG2Meta?.path ?? "/products/:category()/:type()",
    meta: _91type_93EYzK05ukG2Meta || {},
    alias: _91type_93EYzK05ukG2Meta?.alias || [],
    redirect: _91type_93EYzK05ukG2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93EYzK05ukG2Meta?.name ?? "products-category-type___nl",
    path: _91type_93EYzK05ukG2Meta?.path ?? "/nl/products/:category()/:type()",
    meta: _91type_93EYzK05ukG2Meta || {},
    alias: _91type_93EYzK05ukG2Meta?.alias || [],
    redirect: _91type_93EYzK05ukG2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93EYzK05ukG2Meta?.name ?? "products-category-type___en",
    path: _91type_93EYzK05ukG2Meta?.path ?? "/en/products/:category()/:type()",
    meta: _91type_93EYzK05ukG2Meta || {},
    alias: _91type_93EYzK05ukG2Meta?.alias || [],
    redirect: _91type_93EYzK05ukG2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93EYzK05ukG2Meta?.name ?? "products-category-type___de",
    path: _91type_93EYzK05ukG2Meta?.path ?? "/de/products/:category()/:type()",
    meta: _91type_93EYzK05ukG2Meta || {},
    alias: _91type_93EYzK05ukG2Meta?.alias || [],
    redirect: _91type_93EYzK05ukG2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93EYzK05ukG2Meta?.name ?? "products-category-type___fr",
    path: _91type_93EYzK05ukG2Meta?.path ?? "/fr/products/:category()/:type()",
    meta: _91type_93EYzK05ukG2Meta || {},
    alias: _91type_93EYzK05ukG2Meta?.alias || [],
    redirect: _91type_93EYzK05ukG2Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ahC5nd3s80Meta?.name ?? "qr-slug",
    path: _91_46_46_46slug_93ahC5nd3s80Meta?.path ?? "/qr/:slug(.*)*",
    meta: _91_46_46_46slug_93ahC5nd3s80Meta || {},
    alias: _91_46_46_46slug_93ahC5nd3s80Meta?.alias || [],
    redirect: _91_46_46_46slug_93ahC5nd3s80Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ahC5nd3s80Meta?.name ?? "qr-slug___nl",
    path: _91_46_46_46slug_93ahC5nd3s80Meta?.path ?? "/nl/qr/:slug(.*)*",
    meta: _91_46_46_46slug_93ahC5nd3s80Meta || {},
    alias: _91_46_46_46slug_93ahC5nd3s80Meta?.alias || [],
    redirect: _91_46_46_46slug_93ahC5nd3s80Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ahC5nd3s80Meta?.name ?? "qr-slug___en",
    path: _91_46_46_46slug_93ahC5nd3s80Meta?.path ?? "/en/qr/:slug(.*)*",
    meta: _91_46_46_46slug_93ahC5nd3s80Meta || {},
    alias: _91_46_46_46slug_93ahC5nd3s80Meta?.alias || [],
    redirect: _91_46_46_46slug_93ahC5nd3s80Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ahC5nd3s80Meta?.name ?? "qr-slug___de",
    path: _91_46_46_46slug_93ahC5nd3s80Meta?.path ?? "/de/qr/:slug(.*)*",
    meta: _91_46_46_46slug_93ahC5nd3s80Meta || {},
    alias: _91_46_46_46slug_93ahC5nd3s80Meta?.alias || [],
    redirect: _91_46_46_46slug_93ahC5nd3s80Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ahC5nd3s80Meta?.name ?? "qr-slug___fr",
    path: _91_46_46_46slug_93ahC5nd3s80Meta?.path ?? "/fr/qr/:slug(.*)*",
    meta: _91_46_46_46slug_93ahC5nd3s80Meta || {},
    alias: _91_46_46_46slug_93ahC5nd3s80Meta?.alias || [],
    redirect: _91_46_46_46slug_93ahC5nd3s80Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/qr/[...slug].vue").then(m => m.default || m)
  },
  {
    name: quick_45adddADd42qHnbMeta?.name ?? "quick-add",
    path: quick_45adddADd42qHnbMeta?.path ?? "/quick-add",
    meta: quick_45adddADd42qHnbMeta || {},
    alias: quick_45adddADd42qHnbMeta?.alias || [],
    redirect: quick_45adddADd42qHnbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/quick-add.vue").then(m => m.default || m)
  },
  {
    name: quick_45adddADd42qHnbMeta?.name ?? "quick-add___nl",
    path: quick_45adddADd42qHnbMeta?.path ?? "/nl/quick-add",
    meta: quick_45adddADd42qHnbMeta || {},
    alias: quick_45adddADd42qHnbMeta?.alias || [],
    redirect: quick_45adddADd42qHnbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/quick-add.vue").then(m => m.default || m)
  },
  {
    name: quick_45adddADd42qHnbMeta?.name ?? "quick-add___en",
    path: quick_45adddADd42qHnbMeta?.path ?? "/en/quick-add",
    meta: quick_45adddADd42qHnbMeta || {},
    alias: quick_45adddADd42qHnbMeta?.alias || [],
    redirect: quick_45adddADd42qHnbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/quick-add.vue").then(m => m.default || m)
  },
  {
    name: quick_45adddADd42qHnbMeta?.name ?? "quick-add___de",
    path: quick_45adddADd42qHnbMeta?.path ?? "/de/quick-add",
    meta: quick_45adddADd42qHnbMeta || {},
    alias: quick_45adddADd42qHnbMeta?.alias || [],
    redirect: quick_45adddADd42qHnbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/quick-add.vue").then(m => m.default || m)
  },
  {
    name: quick_45adddADd42qHnbMeta?.name ?? "quick-add___fr",
    path: quick_45adddADd42qHnbMeta?.path ?? "/fr/quick-add",
    meta: quick_45adddADd42qHnbMeta || {},
    alias: quick_45adddADd42qHnbMeta?.alias || [],
    redirect: quick_45adddADd42qHnbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/quick-add.vue").then(m => m.default || m)
  },
  {
    name: register_45as_45dealerFHqkeWGTdJMeta?.name ?? "register-as-dealer",
    path: register_45as_45dealerFHqkeWGTdJMeta?.path ?? "/register-as-dealer",
    meta: register_45as_45dealerFHqkeWGTdJMeta || {},
    alias: register_45as_45dealerFHqkeWGTdJMeta?.alias || [],
    redirect: register_45as_45dealerFHqkeWGTdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue").then(m => m.default || m)
  },
  {
    name: register_45as_45dealerFHqkeWGTdJMeta?.name ?? "register-as-dealer___nl",
    path: register_45as_45dealerFHqkeWGTdJMeta?.path ?? "/nl/register-as-dealer",
    meta: register_45as_45dealerFHqkeWGTdJMeta || {},
    alias: register_45as_45dealerFHqkeWGTdJMeta?.alias || [],
    redirect: register_45as_45dealerFHqkeWGTdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue").then(m => m.default || m)
  },
  {
    name: register_45as_45dealerFHqkeWGTdJMeta?.name ?? "register-as-dealer___en",
    path: register_45as_45dealerFHqkeWGTdJMeta?.path ?? "/en/register-as-dealer",
    meta: register_45as_45dealerFHqkeWGTdJMeta || {},
    alias: register_45as_45dealerFHqkeWGTdJMeta?.alias || [],
    redirect: register_45as_45dealerFHqkeWGTdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue").then(m => m.default || m)
  },
  {
    name: register_45as_45dealerFHqkeWGTdJMeta?.name ?? "register-as-dealer___de",
    path: register_45as_45dealerFHqkeWGTdJMeta?.path ?? "/de/register-as-dealer",
    meta: register_45as_45dealerFHqkeWGTdJMeta || {},
    alias: register_45as_45dealerFHqkeWGTdJMeta?.alias || [],
    redirect: register_45as_45dealerFHqkeWGTdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue").then(m => m.default || m)
  },
  {
    name: register_45as_45dealerFHqkeWGTdJMeta?.name ?? "register-as-dealer___fr",
    path: register_45as_45dealerFHqkeWGTdJMeta?.path ?? "/fr/register-as-dealer",
    meta: register_45as_45dealerFHqkeWGTdJMeta || {},
    alias: register_45as_45dealerFHqkeWGTdJMeta?.alias || [],
    redirect: register_45as_45dealerFHqkeWGTdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register-as-dealer.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register",
    path: registerQphDd3L40XMeta?.path ?? "/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register___nl",
    path: registerQphDd3L40XMeta?.path ?? "/nl/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register___en",
    path: registerQphDd3L40XMeta?.path ?? "/en/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register___de",
    path: registerQphDd3L40XMeta?.path ?? "/de/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerQphDd3L40XMeta?.name ?? "register___fr",
    path: registerQphDd3L40XMeta?.path ?? "/fr/register",
    meta: registerQphDd3L40XMeta || {},
    alias: registerQphDd3L40XMeta?.alias || [],
    redirect: registerQphDd3L40XMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91type_93ml185TTSVZMeta?.name ?? "second-chances-category-type",
    path: _91type_93ml185TTSVZMeta?.path ?? "/second-chances/:category()/:type()",
    meta: _91type_93ml185TTSVZMeta || {},
    alias: _91type_93ml185TTSVZMeta?.alias || [],
    redirect: _91type_93ml185TTSVZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93ml185TTSVZMeta?.name ?? "second-chances-category-type___nl",
    path: _91type_93ml185TTSVZMeta?.path ?? "/nl/second-chances/:category()/:type()",
    meta: _91type_93ml185TTSVZMeta || {},
    alias: _91type_93ml185TTSVZMeta?.alias || [],
    redirect: _91type_93ml185TTSVZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93ml185TTSVZMeta?.name ?? "second-chances-category-type___en",
    path: _91type_93ml185TTSVZMeta?.path ?? "/en/second-chances/:category()/:type()",
    meta: _91type_93ml185TTSVZMeta || {},
    alias: _91type_93ml185TTSVZMeta?.alias || [],
    redirect: _91type_93ml185TTSVZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93ml185TTSVZMeta?.name ?? "second-chances-category-type___de",
    path: _91type_93ml185TTSVZMeta?.path ?? "/de/second-chances/:category()/:type()",
    meta: _91type_93ml185TTSVZMeta || {},
    alias: _91type_93ml185TTSVZMeta?.alias || [],
    redirect: _91type_93ml185TTSVZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93ml185TTSVZMeta?.name ?? "second-chances-category-type___fr",
    path: _91type_93ml185TTSVZMeta?.path ?? "/fr/second-chances/:category()/:type()",
    meta: _91type_93ml185TTSVZMeta || {},
    alias: _91type_93ml185TTSVZMeta?.alias || [],
    redirect: _91type_93ml185TTSVZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/second-chances/[category]/[type].vue").then(m => m.default || m)
  },
  {
    name: service0rCUCoSmvhMeta?.name ?? "service",
    path: service0rCUCoSmvhMeta?.path ?? "/service",
    meta: service0rCUCoSmvhMeta || {},
    alias: service0rCUCoSmvhMeta?.alias || [],
    redirect: service0rCUCoSmvhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/service.vue").then(m => m.default || m)
  },
  {
    name: service0rCUCoSmvhMeta?.name ?? "service___nl",
    path: service0rCUCoSmvhMeta?.path ?? "/nl/service",
    meta: service0rCUCoSmvhMeta || {},
    alias: service0rCUCoSmvhMeta?.alias || [],
    redirect: service0rCUCoSmvhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/service.vue").then(m => m.default || m)
  },
  {
    name: service0rCUCoSmvhMeta?.name ?? "service___en",
    path: service0rCUCoSmvhMeta?.path ?? "/en/service",
    meta: service0rCUCoSmvhMeta || {},
    alias: service0rCUCoSmvhMeta?.alias || [],
    redirect: service0rCUCoSmvhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/service.vue").then(m => m.default || m)
  },
  {
    name: service0rCUCoSmvhMeta?.name ?? "service___de",
    path: service0rCUCoSmvhMeta?.path ?? "/de/service",
    meta: service0rCUCoSmvhMeta || {},
    alias: service0rCUCoSmvhMeta?.alias || [],
    redirect: service0rCUCoSmvhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/service.vue").then(m => m.default || m)
  },
  {
    name: service0rCUCoSmvhMeta?.name ?? "service___fr",
    path: service0rCUCoSmvhMeta?.path ?? "/fr/service",
    meta: service0rCUCoSmvhMeta || {},
    alias: service0rCUCoSmvhMeta?.alias || [],
    redirect: service0rCUCoSmvhMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/service.vue").then(m => m.default || m)
  },
  {
    name: teamn1IHArbaS9Meta?.name ?? "team",
    path: teamn1IHArbaS9Meta?.path ?? "/team",
    meta: teamn1IHArbaS9Meta || {},
    alias: teamn1IHArbaS9Meta?.alias || [],
    redirect: teamn1IHArbaS9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/team.vue").then(m => m.default || m)
  },
  {
    name: teamn1IHArbaS9Meta?.name ?? "team___nl",
    path: teamn1IHArbaS9Meta?.path ?? "/nl/team",
    meta: teamn1IHArbaS9Meta || {},
    alias: teamn1IHArbaS9Meta?.alias || [],
    redirect: teamn1IHArbaS9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/team.vue").then(m => m.default || m)
  },
  {
    name: teamn1IHArbaS9Meta?.name ?? "team___en",
    path: teamn1IHArbaS9Meta?.path ?? "/en/team",
    meta: teamn1IHArbaS9Meta || {},
    alias: teamn1IHArbaS9Meta?.alias || [],
    redirect: teamn1IHArbaS9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/team.vue").then(m => m.default || m)
  },
  {
    name: teamn1IHArbaS9Meta?.name ?? "team___de",
    path: teamn1IHArbaS9Meta?.path ?? "/de/team",
    meta: teamn1IHArbaS9Meta || {},
    alias: teamn1IHArbaS9Meta?.alias || [],
    redirect: teamn1IHArbaS9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/team.vue").then(m => m.default || m)
  },
  {
    name: teamn1IHArbaS9Meta?.name ?? "team___fr",
    path: teamn1IHArbaS9Meta?.path ?? "/fr/team",
    meta: teamn1IHArbaS9Meta || {},
    alias: teamn1IHArbaS9Meta?.alias || [],
    redirect: teamn1IHArbaS9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/team.vue").then(m => m.default || m)
  }
]