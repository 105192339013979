import { defu } from 'defu'
import type { UseFetchOptions } from '#app'

export function useApi<T>(url: MaybeRefOrGetter<string>, options: UseFetchOptions<T> = {}) {
  const { $i18n } = useNuxtApp()
  const { token } = useAuth()
  const config = useRuntimeConfig()
  const regionStore = useRegionStore()
  if (!config.public.api.baseUrl)
    throw new Error('Missing baseUrl in nuxt.config.js')

  const defaultHeaders = {
    Country: regionStore.currentRegion,
    Language: $i18n.locale,
  }

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.api.baseUrl ?? undefined,
    headers: token.value ? { ...defaultHeaders, Authorization: token.value } : defaultHeaders,
  }

  const params = defu(options, defaults)
  return useFetch(url, params)
}
