import { defineStore } from 'pinia'

interface ProductCategory {
  id: number
  code: string
  slug: string
  title: string
  groups: string[]
  children?: ProductCategory[]
  description?: string
}

export const useProductStore = defineStore('product', () => {
  const productCategories = ref<ProductCategory[]>([])

  function getProductCategories() {
    return useApi<ProductCategory[]>('/api/products/categories', {
      method: 'GET',
      key: 'product-categories',
      watch: false,
      onResponse({ response }) {
        productCategories.value = response._data as ProductCategory[]
      },
    })
  }

  const { locale } = useI18n()

  watch(locale, () => {
    getProductCategories()
  })

  const productsByCategory = computed(() => {
    const garden: ProductCategory[] = []
    const deco: ProductCategory[] = []
    const balcony: ProductCategory[] = []

    productCategories.value.forEach((product) => {
      if (product.groups.includes('garden'))
        garden.push(product)

      if (product.groups.includes('deco'))
        deco.push(product)

      if (product.groups.includes('balcony'))
        balcony.push(product)
    })
    return {
      garden,
      deco,
      balcony,
    }
  })

  const combinedProductCategories = computed(() => {
    return [
      ...productsByCategory.value.garden,
      ...productsByCategory.value.deco,
      ...productsByCategory.value.balcony,
    ]
  })

  const productList = ref<any[]>([])

  function getProductList(category: string, type: string = 'all') {
    const query = {
      category_slug: type !== 'all' ? type : undefined,
      group: category,
    }

    return useApi<any[]>('/api/products', {
      method: 'GET',
      query,
      key: `products-${category}-${type}`,
      watch: false,
      onResponse({ response }) {
        productList.value = response._data
      },
    })
  }

  return {
    productCategories,
    productList,
    productsByCategory,
    combinedProductCategories,
    getProductCategories,
    getProductList,
  }
})
