// @ts-nocheck

import locale__opt_atlassian_pipelines_agent_build_locales_nl_json from "../locales/nl.json" assert { type: "json" };
import locale__opt_atlassian_pipelines_agent_build_locales_en_json from "../locales/en.json" assert { type: "json" };
import locale__opt_atlassian_pipelines_agent_build_locales_de_json from "../locales/de.json" assert { type: "json" };
import locale__opt_atlassian_pipelines_agent_build_locales_fr_json from "../locales/fr.json" assert { type: "json" };

export const localeCodes = [
  "nl",
  "en",
  "de",
  "fr"
];

export const localeMessages = { 
  "nl": [
      { key: "../locales/nl.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_nl_json), cache: true },
  ],
  "en": [
      { key: "../locales/en.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_en_json), cache: true },
  ],
  "de": [
      { key: "../locales/de.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_de_json), cache: true },
  ],
  "fr": [
      { key: "../locales/fr.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_fr_json), cache: true },
  ],
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */),
]

export const nuxtI18nOptions = {
  "experimental": {},
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl",
      "iso": "nl-BE",
      "files": [
        "locales/nl.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "files": [
        "locales/de.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "files": [
        "locales/fr.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {},
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "nl",
      "iso": "nl-BE",
      "files": [
        {
          "path": "locales/nl.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        {
          "path": "locales/en.json"
        }
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "files": [
        {
          "path": "locales/de.json"
        }
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "files": [
        {
          "path": "locales/fr.json"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
