import revive_payload_client_9PTkVpvH86 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xBagjQsewe from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_p4rfx1SzpF from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_39dkYVmy18 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.2_@unocss+reset@0.60.4_axios@1.6.7_floating-vue@5._ijtha56q52svg7zuis4qo6z7bq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_gOezISuv4M from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_N9QJehYm4j from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_HeEyaAXzsB from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.15_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_k25Emly1OH from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/atlassian/pipelines/agent/build/.nuxt/primevue-plugin.mjs";
import plugin_client_LND6rzznVW from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-primevue@0.2.2_rollup@4.18.0_vue@3.4.15_typescript@5.4.5_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_w78q50IF4v from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.3-alpha.1_@opentelemetry+api@1.8.0_encoding@0.1.13_next-auth@4.24.7_n_r2q4ii5kkrnbw25qz4xyeso4fy/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import composition_lwjFi3MZTi from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@4.18.0_vue-router@4.2.5_vue@3.4.15_typescript@5.4.5___vue@3.4.15_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_SUDscpo7OC from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_rollup@4.18.0_vue-router@4.2.5_vue@3.4.15_typescript@5.4.5___vue@3.4.15_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/opt/atlassian/pipelines/agent/build/.nuxt/unocss.mjs";
import plugin_nM77iXLQHo from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@formkit+auto-animate@0.8.1/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import chunk_reload_client_njazQ3D290 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.1_@unocss+reset@0_dzniy3py5oyeevplp3cmfedrxm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_NZzfKME1kk from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@_b4ijomzc24q5okd4g5wabftnky/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import gtm_cXsUZobmSF from "/opt/atlassian/pipelines/agent/build/plugins/gtm.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.ts";
import vue_social_sharing_ZALq4hYFAx from "/opt/atlassian/pipelines/agent/build/plugins/vue-social-sharing.ts";
import vue_toastification_gZJw6dQQf2 from "/opt/atlassian/pipelines/agent/build/plugins/vue-toastification.ts";
export default [
  revive_payload_client_9PTkVpvH86,
  unhead_xBagjQsewe,
  router_p4rfx1SzpF,
  _0_siteConfig_39dkYVmy18,
  payload_client_gOezISuv4M,
  check_outdated_build_client_N9QJehYm4j,
  plugin_vue3_HeEyaAXzsB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k25Emly1OH,
  primevue_plugin_egKpok8Auk,
  plugin_client_LND6rzznVW,
  plugin_w78q50IF4v,
  composition_lwjFi3MZTi,
  i18n_SUDscpo7OC,
  unocss_MzCDxu9LMj,
  plugin_nM77iXLQHo,
  chunk_reload_client_njazQ3D290,
  plugin_NZzfKME1kk,
  gtm_cXsUZobmSF,
  sentry_client_shVUlIjFLk,
  vue_social_sharing_ZALq4hYFAx,
  vue_toastification_gZJw6dQQf2
]