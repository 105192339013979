<script setup lang="ts">
const cartStore = useShoppingCartStore()
const productStore = useProductStore()
const canonicalUrl = useCanonicalURL()
const { locale } = useI18n()

cartStore.getShoppingCart()
await productStore.getProductCategories()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Giardino` : 'Giardino'
  },
  htmlAttrs: {
    lang: locale.value,
  },
  link: [
    {
      rel: 'canonical',
      href: canonicalUrl,
    },
  ],
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <NuxtLoadingIndicator color="#DC7818" />
  </div>
</template>
