import { withoutTrailingSlash } from 'ufo'

export default function useCanonicalURL() {
  const baseUrl = 'https://giardino-online.com'
  const route = useRoute()
  const path = withoutTrailingSlash(route.path)

  return computed(() => {
    return `${baseUrl}${path}`
  })
}
