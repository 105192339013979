import { defineStore } from 'pinia'

export enum Region {
  BELGIUM = 'be',
  NETHERLANDS = 'nl',
  LUXEMBOURG = 'lu',
}

export const useRegionStore = defineStore('region', () => {
  // type Region = 'be' | 'nl' | 'lu'

  function getNextYear() {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    return date
  }

  const currentRegion = useCookie<Region>('region', {
    expires: getNextYear(),
    default: () => Region.BELGIUM,
  })

  return {
    currentRegion,
  }
})
