import { defineStore } from 'pinia'

const addressSchema = z.object({
  street: z.string(),
  street_nr: z.string(),
  bus: z.string(),
  postcode: z.string(),
  city: z.string(),
})

export const userSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  phonenumber: z.string(),
  email: z.string().email(),
  language: z.enum(['nl', 'fr', 'de', 'en']),
  newsletter: z.boolean(),
  billing_address: addressSchema.optional(),
  shipping_address: addressSchema.optional(),
  created_at: z.string(),
  professional: z.boolean(),
  role: z.enum(['b2b', 'b2c']),
  token: z.string(),
})

export type UserType = Zod.infer<typeof userSchema>

export const useAuthStore = defineStore('auth', () => {
  const {
    status,
    data,
    token,
    lastRefreshedAt,
    getSession,
    signUp,
    signIn,
    signOut,
  } = useAuth()

  const config = useRuntimeConfig()
  const localeRoute = useLocaleRoute()
  const localePath = useLocalePath()

  // getSession() // Wordt nu in app.vue async gedaan via fetchMe()

  async function login(credentials: { username: string, password: string }) {
    // navigateTo(localeRoute({ name: 'index' }))
    return signIn({
      ...credentials,
      client_id: config.public.passport.clientId,
      client_secret: config.public.passport.clientSecret,
      grant_type: 'password',
    }, {
      callbackUrl: localePath({ name: 'cart' }),
    })
  }

  function logout() {
    return signOut({ callbackUrl: localePath({ name: 'index' }) })
  }

  function fetchMe() {
    return getSession()
  }

  const isAuthenticated = computed(() => status.value === 'authenticated')

  const isB2B = computed(() => data.value?.role === 'b2b')

  return {
    status,
    token,
    isAuthenticated,
    lastRefreshedAt,
    me: data,
    isB2B,

    fetchMe,
    login,
    logout,
  }
})
